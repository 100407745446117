import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { Container, Col, Row } from "react-bootstrap";
import { Link, navigate } from "gatsby"
import axios from "axios"

// the below async function wait till get data from strapi using graphql
const getPropurl = async (handler, crmid) => {
  let result = [];
  //instead of hit REST we use json format to get only required fields. we dont import apollo since this call from another Hook
  var data = JSON.stringify({
    "query": "query($crm_id:[String]) { properties(where: { master_id: $crm_id}) { id, slug, search_type, department } }",
    "variables": { "crm_id": [crmid] }
  });
  console.log("🚀 ~ file: 404.js ~ line 17 ~ getPropurl ~ data", data)
  var config = {
    method: "post",
    url: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
    data: data,
  }
  const res = await axios(config)
  result[0] = JSON.stringify(res.data)
  handler(result) //update state var
}

const Page404 = (props) => {
  const [showtemplate, setShowtemplate] = React.useState(false)
  const [propdata, setPropdata] = React.useState("") // false

  React.useEffect(() => {

    // unpublished properties 404 page
    if(props?.publish == false){
      setShowtemplate(true)
    }
    //we doing here as fallback, if any case build failed or not triggered then the below will be useful; when build happens redirects will happen a t netlify itself, so dont need to do CSR
    let regex = ""; //you can modify the preg as per proj feed
    if (props?.location?.pathname) {
      let crm_id = "";
      if (props?.location?.pathname) {
        regex = "\[a-zA-Z]{2}-[0-9]{4,6}";  //you can modify the preg as per proj feed
      }
      crm_id = (props?.location?.pathname).match(regex);
      //mke sure state value is empty and we have proper url to proceed
      if (typeof crm_id != "undefined" && crm_id != null && crm_id.length > 0 && !propdata) {
        getPropurl(setPropdata, crm_id[0]) // call the function to fetch data from strapi
      } else if (propdata) {
        let property_data = JSON.parse(propdata)
        let property_arr = property_data.data.properties
        if (property_arr.length > 0) {
          let property = property_arr[0]
          //set the redirection logic, same mentioned in the gatsby-node for _redirects.txt ie., crm_id to details page
          if (property.department == "residential") {
            if (property.id) {
              if (property.search_type == "sales") {
                navigate(`/property-for-sale/${property.slug}-${property.id}`, {
                  replace: true,
                })
              } else if (property.search_type == "lettings") {
                navigate(`/property-for-rent/${property.slug}-${property.id}`, {
                  replace: true,
                })
              }
            }
          } else if (property.department == "commercial") {
            if (property.id) {
              if (property.search_type == "sales") {
                navigate(`/commercial-property-for-sale/${property.slug}-${property.id}`, {
                  replace: true,
                })
              } else if (property.search_type == "lettings") {
                navigate(`/commercial-property-for-rent/${property.slug}-${property.id}`, {
                  replace: true,
                })
              }
            }
          } else if (property.department == "short_lets") {
            if (property.id) {
              if (property.search_type == "sales") {
                navigate(`/holiday-homes-for-sale/${property.slug}-${property.id}`, {
                  replace: true,
                })
              } else if (property.search_type == "lettings") {
                navigate(`/holiday-homes-for-rent/${property.slug}-${property.id}`, {
                  replace: true,
                })
              }
            }
          }
        } else {
          setShowtemplate(true) // show 404 page template
        }
      } else {
        setShowtemplate(true) // show 404 page template
      }
    }
  }, [propdata])

  return (
    <React.Fragment>
      {showtemplate &&
        <>
          <Header layout="404" />
          <section className="errorpage margin-for-header section-p">
            <Container>
              <Row className="d-flex justify-content-center bd-highlight mb-3 text-center">
                <Col md="9">
                  <h1>We're sorry, the page you were looking for cannot be found.</h1>
                  <p>This may be because it has been removed, we've changed its name or it is temporarily unavailable.</p>
                  <div class="btn-wrapper">
                    <Link to="/properties/for-sale/in-dubai/" class="button button-primary">Find a property</Link>
                    <Link to="/" class="button button-primary share">Homepage</Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer categoryType="category1" />
        </>
      }
    </React.Fragment>
  )
}
export default Page404;

